import { Edge, EdgeTypes, Node, NodeTypes, ReactFlowJsonObject } from 'reactflow'
import { InputNode } from '../components/Flow/InputNode'
import { ProductNode } from '../components/Flow/ProductNode'
import { InputEdge } from '../components/Flow/InputEdge'
import InputService, { Input } from './input'
import ProductService, { Product } from './product'
import Utils from './utils'
import { ActionMap, ApplicationContextInterface } from '../context'
import { PartNode } from '../components/Flow/PartNode'
import { SourceProductNode } from '../components/Flow/SourceProductNode'
import { OrgNode } from '../components/Org/OrgNode'
import { Part } from './part'
import { ContainerNode } from '../components/Flow/ContainerNode'
import VariableService from './service'
import { RecycledEdge } from '../components/Flow/RecycledEdge'
import { ByproductNode } from '../components/Flow/ByproductNode'
import { ByproductEdge } from '../components/Flow/ByproductEdge'
import { Amount, UsedIn, VariableNode } from '../types'
import { XYPosition } from '@reactflow/core/dist/esm/types'
import { AssemblyNode } from '../components/Flow/AssemblyNode'
import { DownstreamNode } from '../components/Flow/DownstreamNode'
import ProcessingService, { ByproductType } from './processing'
import FlagService, { FlagType } from './flag'

type NodeType =
    | 'product'
    | 'input'
    | 'transport'
    | 'processing'
    | 'byproduct'
    | 'part'
    | 'sourceProduct'
    | 'assembly'
    | 'downstream'

export const PRODUCT_NODE_WIDTH = 300
export const PRODUCT_NODE_HEIGHT = 100
export const INPUT_NODE_WIDTH = 250
export const INPUT_NODE_HEIGHT = 60
export const SOURCE_PRODUCT_NODE_WIDTH = 250
export const PART_NODE_WIDTH = 300
export const PART_NODE_HEIGHT = 150
export const ORG_NODE_WIDTH = 150
export const ORG_NODE_HEIGHT = 75
export const ORG_NODE_SPACING = 50
export const NODE_POSITION_OFFSET = 250
export const EDGE_WIDTH = 120
export const FLOW_SPACING = 50
export const ASSEMBLY_NODE_PADDING_TOP = 50
export const ASSEMBLY_NODE_PADDING_BOTTOM = 140
export const SOURCE_PRODUCT_UPSTREAM_COLUMN = -4
export const TRANSPORT_UPSTREAM_COLUMN = -3
export const PROCESSING_UPSTREAM_COLUMN = -2
export const BYPRODUCT_UPSTREAM_COLUMN = -2
export const INPUT_UPSTREAM_COLUMN = -2
export const ASSEMBLY_UPSTREAM_COLUMN = -1
export const SOURCE_PRODUCT_DOWNSTREAM_COLUMN = 4
export const TRANSPORT_DOWNSTREAM_COLUMN = 2
export const PROCESSING_DOWNSTREAM_COLUMN = 3
export const BYPRODUCT_DOWNSTREAM_COLUMN = 4
export const INPUT_DOWNSTREAM_COLUMN = 1

export const variableEdgeTypes: EdgeTypes = {
    inputEdge: InputEdge,
    recycledEdge: RecycledEdge,
    byproductEdge: ByproductEdge,
}

export const variableNodeTypes: NodeTypes = {
    inputNode: InputNode,
    partNode: PartNode,
    productNode: ProductNode,
    assemblyNode: AssemblyNode,
    downstreamNode: DownstreamNode,
    sourceProductNode: SourceProductNode,
    byproductNode: ByproductNode,
    orgNode: OrgNode,
    containerNode: ContainerNode,
}

export enum FootprintFlowActionType {
    Set = 'SetFootprintFlow',
    SetNodes = 'SetFootprintFlowNodes',
    SetEdges = 'SetFootprintFlowEdges',
    Add = 'AddToFootprintFlow',
    RemoveNodeById = 'RemoveNodeByIdFromFootprintFlow',
    Refresh = 'RefreshFootprintFlow',
    Reset = 'ResetFootprintFlow',
}

export interface FlowConfig {
    key?: string
    product?: Product
    inputs?: Input[]
    nodes: Node[]
    edges: Edge[]
    refresh?: number
}

export interface FlowRenderConfig {
    product?: Product
    parentProduct?: Product
    parentInput?: Input
    recursive?: boolean
    subRender?: boolean
    updating?: boolean
    reset?: boolean
}

interface SourceAndTargetOptions {
    input?: Input
    source: string
    sourceHandle?: string
    target: string
    targetHandle?: string
}

interface SourceAndTarget {
    id: string
    source: string
    target: string
    sourceHandle?: string
    targetHandle?: string
}

export const initialFlowConfig: FlowConfig = {
    key: undefined,
    product: undefined,
    inputs: [],
    nodes: [],
    edges: [],
    refresh: 0,
}

type FootprintFlowActionPayload = {
    [FootprintFlowActionType.Set]: FlowConfig
    [FootprintFlowActionType.SetNodes]: Node[]
    [FootprintFlowActionType.SetEdges]: Edge[]
    [FootprintFlowActionType.Add]: { node: Node; edge: Edge }
    [FootprintFlowActionType.RemoveNodeById]: string
    [FootprintFlowActionType.Refresh]: undefined
    [FootprintFlowActionType.Reset]: undefined
}

export type FootprintFlowActions = ActionMap<FootprintFlowActionPayload>[keyof ActionMap<FootprintFlowActionPayload>]

export const FootprintFlowReducer = (state: FlowConfig, action: FootprintFlowActions): FlowConfig => {
    // if (action.type.includes('Flow')) {
    //     // @ts-ignore
    //     console.log(action.type, action?.payload)
    // }
    switch (action.type) {
        case FootprintFlowActionType.Set:
            return action.payload
        case FootprintFlowActionType.Refresh:
            return {
                ...state,
                refresh: (state.refresh || 0) + 1,
            }
        case FootprintFlowActionType.Reset:
            return { ...initialFlowConfig }
        case FootprintFlowActionType.SetNodes:
            return {
                ...state,
                nodes: action.payload,
                edges: state.edges,
            }
        case FootprintFlowActionType.SetEdges:
            return {
                ...state,
                nodes: state.nodes,
                edges: action.payload,
            }
        case FootprintFlowActionType.Add:
            return {
                ...state,
                nodes: [...state.nodes, action.payload.node],
                edges: [...state.edges, action.payload.edge],
            }
        case FootprintFlowActionType.RemoveNodeById:
            return {
                ...state,
                nodes: state.nodes.filter((n) => n.id !== action.payload),
                edges: state.edges.filter((e) => !e.id?.includes(action.payload)),
                refresh: (state.refresh || 0) + 1,
            }
        default:
            return state
    }
}

export class FlowService extends VariableService {
    private readonly productService: ProductService
    private readonly inputService: InputService
    private readonly processingService: ProcessingService
    private readonly maxFlowDepth: number = 20

    constructor(context: ApplicationContextInterface) {
        super(context)
        this.productService = new ProductService(context)
        this.inputService = new InputService(context)
        this.processingService = new ProcessingService(context)
    }

    setContext(context: ApplicationContextInterface) {
        super.setContext(context)
        this.productService.setContext(context)
        this.inputService.setContext(context)
    }

    public isEnabled(): boolean {
        return FlagService.enabledFlags.has(FlagType.UseReactFlow)
    }

    public static saveTimer: NodeJS.Timeout

    public static nodeHighlightClass = 'outline outline-1 outline-primary'

    public static saveFlowById(id?: string, value?: ReactFlowJsonObject | null) {
        if (!id) {
            console.warn('saveFlowById: id is required')
            return
        }
        if (this.saveTimer) {
            clearTimeout(this.saveTimer)
        }
        this.saveTimer = setTimeout(() => {
            if (value === null) {
                localStorage.removeItem(id)
            } else {
                localStorage.setItem(id, JSON.stringify(value))
            }
        }, 300)
    }

    public static getFlowById(id: string): ReactFlowJsonObject {
        const _flow = localStorage.getItem(id)
        return JSON.parse(_flow || '{}')
    }

    public getNodeId(type: NodeType, input?: Input, vNode?: VariableNode): string {
        if (input?.uuid && vNode?.uuid) {
            return `${type}-${input?.uuid}-${vNode?.uuid}`
        } else if (!input && vNode?.uuid) {
            return `${type}-${vNode?.uuid}`
        } else {
            return `${type}-${input?.uuid}`
        }
    }

    public getInputNodeId(input?: Input, _frc?: FlowRenderConfig): string {
        return `input-${input?.uuid}`
    }

    public getPartNodeId(input?: Input, part?: Part | UsedIn, _frc?: FlowRenderConfig): string {
        return `part-${part?.uuid}-${input?.uuid}`
    }

    public getProductNodeId(product?: Product | null, input?: Input, _frc?: FlowRenderConfig): string {
        return `product-${input?.uuid}-${product?.uuid}`
    }

    public getAssemblyNodeId(product?: Product | null, input?: Input, _frc?: FlowRenderConfig): string {
        return `assembly-${input?.uuid}-${product?.uuid}`
    }

    public getDownstreamNodeId(product?: Product | null, input?: Input, _frc?: FlowRenderConfig): string {
        return `downstream-${input?.uuid}-${product?.uuid}`
    }

    public getTransportNodeId(input?: Input, _transportInput?: Input, _frc?: FlowRenderConfig): string {
        return `transport-${input?.uuid}`
    }

    public getProcessingNodeId(input?: Input, _processingInput?: Input, _frc?: FlowRenderConfig): string {
        return `processing-${input?.uuid}`
    }

    public getEnergyNodeId(input?: Input, _amount?: Amount, _frc?: FlowRenderConfig): string {
        return `energy-${input?.uuid}`
    }

    public getByproductNodeId(input?: Input, byproductType?: ByproductType, _frc?: FlowRenderConfig): string {
        return `byproduct-${input?.uuid}-${byproductType?.uuid}`
    }

    public getSourceAndTarget(opts: SourceAndTargetOptions): SourceAndTarget {
        // console.log(input.name, input.useStageCategory?.type)
        let _st: SourceAndTarget = {
            id: `${opts.source}-${opts.target}`,
            source: opts.source,
            sourceHandle: opts.sourceHandle,
            target: opts.target,
            targetHandle: opts.targetHandle,
        }
        // if (opts.input?.useStageCategory?.type === 'Downstream' && !opts.forceOrder) {
        //     _st.id = `${opts.target}-${opts.source}`
        //     _st.source = opts.target
        //     _st.target = opts.source
        // }
        return _st
    }

    public getStrokeWidth(_totalCo2e?: number, _co2e?: number) {
        return 3
        // const [, _percent] = Utils.percentOfTotal(co2e, totalCo2e)
        // return Utils.normalize(_percent + 10, 0, 40)
    }

    public getColumn(startingX: number, isDownstream: boolean = false, type: NodeType) {
        let _column
        if (isDownstream) {
            switch (type) {
                case 'transport':
                    _column = TRANSPORT_DOWNSTREAM_COLUMN
                    break
                case 'processing':
                    _column = PROCESSING_DOWNSTREAM_COLUMN
                    break
                case 'byproduct':
                    _column = BYPRODUCT_DOWNSTREAM_COLUMN
                    break
                case 'part':
                case 'sourceProduct':
                    _column = SOURCE_PRODUCT_DOWNSTREAM_COLUMN
                    break
                default:
                    _column = INPUT_DOWNSTREAM_COLUMN
                    break
            }
        } else {
            switch (type) {
                case 'transport':
                    _column = TRANSPORT_UPSTREAM_COLUMN
                    break
                case 'processing':
                    _column = PROCESSING_UPSTREAM_COLUMN
                    break
                case 'byproduct':
                    _column = BYPRODUCT_UPSTREAM_COLUMN
                    break
                case 'part':
                case 'sourceProduct':
                    _column = SOURCE_PRODUCT_UPSTREAM_COLUMN
                    break
                case 'assembly':
                    _column = ASSEMBLY_UPSTREAM_COLUMN
                    break
                default:
                    _column = INPUT_UPSTREAM_COLUMN
                    break
            }
        }
        return startingX + (PRODUCT_NODE_WIDTH + FLOW_SPACING) * _column
    }

    public async getNodesAndEdges(frc: FlowRenderConfig, count: number = 0): Promise<FlowConfig> {
        if (count > this.maxFlowDepth) {
            Utils.errorToast(undefined, 'Error rendering data', { toastId: 'max-flow-depth-exceeded' })
            throw new Error('Max flow depth exceeded')
        }

        const fc: FlowConfig = {
            ...this.context.stores.footprintFlow,
            product: frc.product,
            key: (!frc.reset && this.context.stores.footprintFlow.key) || `flow-${frc.product?.uuid}`,
            inputs: (!frc.reset && this.context.stores.footprintFlow.inputs) || [],
            nodes: (!frc.reset && this.context.stores.footprintFlow.nodes) || [],
            edges: (!frc.reset && this.context.stores.footprintFlow.edges) || [],
        }

        const _productCo2e = Utils.Decimal(frc.product?.co2e || 0).toNumber()
        const _productNodeId = this.getProductNodeId(frc.parentProduct || frc.product, frc.parentInput, frc)
        const _assemblyNodeId = this.getAssemblyNodeId(frc.parentProduct || frc.product, frc.parentInput, frc)
        const _downstreamNodeId = this.getDownstreamNodeId(frc.parentProduct || frc.product, frc.parentInput, frc)

        const setNode = (node: Node) => {
            fc.nodes = Utils.mergeArrays(fc.nodes, [node], 'id')
        }

        const removeNodeById = (nodeId: string, includes: boolean = false) => {
            fc.nodes = fc.nodes.filter((n) => {
                if (includes) {
                    return !n.id?.includes(nodeId)
                }
                return n.id !== nodeId
            })
        }

        const setEdge = (edge: Edge) => {
            fc.edges = Utils.mergeArrays(fc.edges, [edge], 'id')
        }

        const getStrokeWidth = this.getStrokeWidth.bind(this, _productCo2e)

        const nextRender: Product[] = []

        const nodesById = new Map<string, Node>(fc.nodes.map((n) => [n.id, n]))

        const getPosition = (nodeType: NodeType, xy: XYPosition, input?: Input, vNode?: VariableNode): XYPosition => {
            if (frc.updating) {
                // TODO: this whole thing here doesn't seem to be working
                // The node positioning is always being reset
                const _node = nodesById.get(this.getNodeId(nodeType, input, vNode))
                return _node?.positionAbsolute || _node?.position || xy
            }
            return xy
        }

        let _startingX = 0
        let _previousUpstreamPosition = { x: 0, y: 0 }
        let _previousDownstreamPosition = { x: 0, y: 0 }

        if (frc.parentProduct?.uuid) {
            const _parentNode = nodesById.get(this.getProductNodeId(frc.parentProduct, frc.parentInput, frc))
            if (_parentNode) {
                _startingX = _parentNode.position.x
                _previousUpstreamPosition = {
                    x: _parentNode.position.x,
                    y: _parentNode.position.y - NODE_POSITION_OFFSET,
                }
                _previousDownstreamPosition = {
                    x: _parentNode.position.x,
                    y: _parentNode.position.y - NODE_POSITION_OFFSET,
                }
            }
        }
        const getColumn = this.getColumn.bind(this, _startingX)

        const inputs = await this.productService.getInputs(frc.product?.uuid!)
        if (!frc.parentInput) {
            fc.inputs?.forEach((i) => {
                if (!inputs.find((i2) => i2.uuid === i.uuid)) {
                    removeNodeById(i.uuid!, true)
                }
            })
        }
        fc.inputs = fc.inputs?.concat(inputs)

        setNode({
            id: _assemblyNodeId,
            type: 'assemblyNode',
            data: { product: frc.product },
            position: { x: 0, y: 0 },
        })

        if (!frc.parentInput) {
            setNode({
                id: _downstreamNodeId,
                type: 'downstreamNode',
                data: { product: frc.product },
                position: { x: 0, y: 0 },
            })
        }

        let _upstreamIndex = 0
        let _upstreamInputCount = 0
        let _downstreamIndex = 0
        let _downstreamInputCount = 0
        inputs
            ?.sort((a, b) => {
                if (a.useStageCategory?.type === 'Upstream' && b.useStageCategory?.type === 'Downstream') {
                    return -1
                }
                if (a.useStageCategory?.type === 'Downstream' && b.useStageCategory?.type === 'Upstream') {
                    return 1
                }
                return 0
            })
            ?.map((i) => InputService.byId.get(i.uuid || '')!)
            ?.map(async (i, idx) => {
                const _isDirect =
                    ['A3', 'A4', 'B1'].includes(i.useStageCategory?.code || '') && !i.transportFor && !i.processingFor
                const _isDownstream = i.useStageCategory?.type === 'Downstream'
                const _isUpstream = i.useStageCategory?.type !== 'Downstream'
                if (!_isDirect && ['transport', 'processing'].includes(InputService.getInputType(i))) {
                    return
                }

                let _y = 1
                if (_isUpstream) {
                    _y = _previousUpstreamPosition.y + NODE_POSITION_OFFSET
                    if (!_isDirect) _previousUpstreamPosition.y = _y
                } else if (_isDownstream) {
                    _y = _previousDownstreamPosition.y + NODE_POSITION_OFFSET
                    if (!_isDirect) _previousDownstreamPosition.y = _y
                }

                let _sourceNodeId = this.getInputNodeId(i, frc)
                let _sourceProductCo2e = Utils.Decimal(0)
                if (i.part?.uuid) {
                    _sourceProductCo2e = Utils.Decimal(i.part?.liveCo2e || 0)
                    _sourceNodeId = this.getPartNodeId(i, i.part, frc)
                    setNode({
                        id: _sourceNodeId,
                        type: 'partNode',
                        data: { input: i, part: i.part, product: frc.product },
                        position: getPosition('part', { x: getColumn(_isDownstream, 'part'), y: _y }, i, i.part),
                        style: { zIndex: -1 },
                    })
                } else if (!_isDirect) {
                    const _spY = _y - (idx ? 0 : 20)
                    _sourceProductCo2e = Utils.Decimal(i.sourceProduct?.upstreamCo2e || i.sourceProduct?.co2e || 0)
                    _sourceNodeId = this.getProductNodeId(i.sourceProduct, i, frc)
                    setNode({
                        id: _sourceNodeId,
                        type: 'sourceProductNode',
                        data: { sourceProduct: i.sourceProduct, product: frc.product, input: i },
                        position: getPosition(
                            'sourceProduct',
                            { x: getColumn(_isDownstream, 'sourceProduct'), y: _spY },
                            i,
                            i.sourceProduct || undefined,
                        ),
                    })
                    if (i.sourceProduct?.uuid) {
                        nextRender.push(i.sourceProduct)
                    }
                }

                const _transportedVia = InputService.byId.get(i.transportedVia?.uuid || '')
                const _processedVia = InputService.byId?.get(i.processedVia?.uuid || '')

                const _transportCo2e = Utils.Decimal(_transportedVia?.co2e || 0)
                const _processingCo2e = Utils.Decimal(_processedVia?.co2e || 0)

                const _inputNodeId = this.getInputNodeId(i, frc)
                const _transportNodeId = this.getTransportNodeId(i, _transportedVia, frc)
                const _processingNodeId = this.getProcessingNodeId(i, _processedVia, frc)
                const _energyNodeId = this.getEnergyNodeId(i, undefined, frc)

                setNode({
                    id: _inputNodeId,
                    type: 'inputNode',
                    parentId: _isDownstream ? _downstreamNodeId : _assemblyNodeId,
                    extent: 'parent',
                    draggable: false,
                    data: { input: i, product: frc.product },
                    zIndex: 20,
                    position: {
                        x: 0,
                        y:
                            (_isDownstream ? 0 : ASSEMBLY_NODE_PADDING_TOP) +
                            INPUT_NODE_HEIGHT * (_isDownstream ? _downstreamIndex : _upstreamIndex),
                    },
                })

                // console.log(i.name, _isUpstream, _isDirect, _isDownstream)
                if (_isUpstream) {
                    _upstreamIndex++
                    _upstreamInputCount++
                } else if (_isDownstream) {
                    _downstreamIndex++
                    _downstreamInputCount++
                }

                if (!_isDirect) {
                    setNode({
                        id: _transportNodeId,
                        type: 'inputNode',
                        data: { label: 'Transport', input: _transportedVia || i, product: frc.product },
                        position: getPosition(
                            'transport',
                            { x: getColumn(_isDownstream, 'transport'), y: _y },
                            i,
                            _transportedVia || i,
                        ),
                    })

                    setNode({
                        id: _processingNodeId,
                        type: 'inputNode',
                        data: { label: 'Processing', input: _processedVia || i, product: frc.product },
                        position: getPosition(
                            'processing',
                            { x: getColumn(_isDownstream, 'processing'), y: _y },
                            i,
                            _processedVia || i,
                        ),
                    })
                }

                if (_processedVia?.processingType) {
                    const _processingType = await this.processingService.getProcessing(
                        _processedVia?.processingType,
                        i.uuid!,
                        true,
                    )
                    // setNode({
                    //     id: _energyNodeId,
                    //     type: 'inputNode',
                    //     data: {
                    //         label: 'Energy',
                    //         input: _processedVia,
                    //         processingType: _processingType,
                    //         product: frc.product,
                    //     },
                    //     position: getPosition(
                    //         'processing',
                    //         { x: getColumn(_isDownstream, 'processing'), y: _y - PRODUCT_NODE_HEIGHT / 1.5 },
                    //         i,
                    //         _processedVia,
                    //     ),
                    // })
                    // setEdge({
                    //     ...this.getSourceAndTarget({
                    //         input: i,
                    //         source: _energyNodeId,
                    //         sourceHandle: 'energySource',
                    //         target: _processingNodeId,
                    //         targetHandle: 'energyTarget',
                    //     }),
                    //     type: 'inputEdge',
                    //     data: { input: _processedVia || i },
                    //     style: { strokeWidth: getStrokeWidth(_processingCo2e.toNumber()), stroke: Utils.scope1Color },
                    // })
                    let _byproductY = _y + 80
                    _processingType.byproducts?.sort(Utils.sortByCreated)?.map(async (bp) => {
                        const _byproductProduct = await this.productService.getById(bp?.footprint?.uuid!, true)
                        const _byproductNodeId = this.getByproductNodeId(i, bp, frc)
                        setNode({
                            id: _byproductNodeId,
                            type: 'byproductNode',
                            data: { byproductType: bp, processingType: _processingType, input: _processedVia },
                            position: getPosition(
                                'byproduct',
                                { x: getColumn(_isDownstream, 'byproduct'), y: _byproductY },
                                i,
                                bp,
                            ),
                        })
                        _byproductY += 60
                        setEdge({
                            ...this.getSourceAndTarget({
                                input: i,
                                source: _processingNodeId,
                                target: _byproductNodeId,
                            }),
                            // animated: true,
                            // type: 'byproductEdge',
                            style: { strokeWidth: 1, stroke: Utils.upstreamColor },
                        })
                        const bpSourceFor = _byproductProduct.usedIn?.filter((ui) => ui.type === 'Part')
                        bpSourceFor?.forEach((sf) => {
                            const _targetPartInput = inputs.filter((i) => i.part?.uuid === sf.uuid)
                            _targetPartInput.forEach((_targetPartInput) => {
                                setEdge({
                                    ...this.getSourceAndTarget({
                                        input: i,
                                        source: _byproductNodeId,
                                        target: this.getPartNodeId(_targetPartInput, sf, frc),
                                    }),
                                    type: 'recycledEdge',
                                    // animated: true,
                                    style: { strokeWidth: 1, stroke: Utils.upstreamColor, zIndex: -1 },
                                })
                            })
                        })
                    })
                } else if (!_processedVia) {
                    removeNodeById(_energyNodeId)
                }

                let _totalCo2e = _sourceProductCo2e
                if (_isUpstream || _isDirect) {
                    setEdge({
                        ...this.getSourceAndTarget({ input: i, source: _sourceNodeId, target: _transportNodeId }),
                        type: 'inputEdge',
                        data: { input: i },
                        style: { strokeWidth: getStrokeWidth(_totalCo2e.toNumber()) },
                    })

                    _totalCo2e = _totalCo2e.plus(_transportCo2e)
                    setEdge({
                        ...this.getSourceAndTarget({ input: i, source: _transportNodeId, target: _processingNodeId }),
                        type: 'inputEdge',
                        data: { input: _transportedVia || i },
                        style: { strokeWidth: getStrokeWidth(_totalCo2e.toNumber()) },
                    })

                    _totalCo2e = _totalCo2e.plus(_processingCo2e)
                    setEdge({
                        ...this.getSourceAndTarget({ input: i, source: _processingNodeId, target: _inputNodeId }),
                        type: 'inputEdge',
                        data: { input: _processedVia || i },
                        style: { strokeWidth: getStrokeWidth(_totalCo2e.toNumber()), stroke: Utils.upstreamColor },
                    })
                } else if (_isDownstream) {
                    _totalCo2e = _totalCo2e.plus(_processingCo2e)
                    setEdge({
                        ...this.getSourceAndTarget({ input: i, source: _processingNodeId, target: _sourceNodeId }),
                        type: 'inputEdge',
                        data: { input: _processedVia || i },
                        style: { strokeWidth: getStrokeWidth(), stroke: Utils.upstreamColor },
                    })

                    _totalCo2e = _totalCo2e.plus(_transportCo2e)
                    setEdge({
                        ...this.getSourceAndTarget({ input: i, source: _transportNodeId, target: _processingNodeId }),
                        type: 'inputEdge',
                        data: { input: _transportedVia || i },
                        style: { strokeWidth: getStrokeWidth(_totalCo2e.toNumber()) },
                    })

                    setEdge({
                        ...this.getSourceAndTarget({ input: i, source: _inputNodeId, target: _transportNodeId }),
                        type: 'inputEdge',
                        data: { input: i },
                        style: { strokeWidth: getStrokeWidth(_totalCo2e.toNumber()) },
                    })
                }
            })

        const _nodeHeight = Math.max(_previousUpstreamPosition.y, _previousDownstreamPosition.y)

        const _assemblyHeight =
            _upstreamInputCount * INPUT_NODE_HEIGHT + ASSEMBLY_NODE_PADDING_TOP + ASSEMBLY_NODE_PADDING_BOTTOM
        const _assemblyPos = { x: getColumn(false, 'assembly'), y: _nodeHeight / 2 - _assemblyHeight / 2 }

        const _downstreamHeight = _downstreamInputCount * INPUT_NODE_HEIGHT + PRODUCT_NODE_HEIGHT
        const _downstreamPos = { x: getColumn(true, 'downstream'), y: _nodeHeight / 2 - _downstreamHeight / 2 }

        const _productY = _assemblyPos.y + Math.max(_assemblyHeight, _downstreamHeight) / 2 - PRODUCT_NODE_HEIGHT / 2
        if (frc.updating || !nodesById.get(this.getProductNodeId(frc.product, frc.parentInput, frc))) {
            setNode({
                id: _productNodeId,
                type: 'productNode',
                data: { product: frc.product, isMain: true },
                position: getPosition('product', { x: 0, y: _productY }, undefined, frc.product),
            })
        }

        setNode({
            id: _assemblyNodeId,
            type: 'assemblyNode',
            data: { product: frc.product, height: _assemblyHeight },
            zIndex: 10,
            position: getPosition('assembly', _assemblyPos, undefined, frc.product),
        })
        setEdge({
            ...this.getSourceAndTarget({ source: _assemblyNodeId, target: _productNodeId }),
            type: 'inputEdge',
            style: { strokeWidth: getStrokeWidth() },
        })

        if (!frc.parentInput) {
            setNode({
                id: _downstreamNodeId,
                type: 'downstreamNode',
                data: { product: frc.product, height: _downstreamHeight },
                zIndex: 10,
                position: getPosition('downstream', _downstreamPos, undefined, frc.product),
            })
            setEdge({
                ...this.getSourceAndTarget({ source: _productNodeId, target: _downstreamNodeId }),
                type: 'inputEdge',
                style: { strokeWidth: getStrokeWidth() },
            })
        }

        if (frc.recursive) {
            nextRender.map(async (p) => {
                const _fc = await this.getNodesAndEdges(
                    {
                        ...frc,
                        product: p,
                        parentProduct: frc.product,
                        recursive: frc.recursive,
                        subRender: true,
                    },
                    count + 1,
                )
                fc.nodes = Utils.mergeArrays(fc.nodes, _fc.nodes, 'id')
                fc.edges = Utils.mergeArrays(fc.nodes, _fc.edges, 'id')
            })
        }

        if (!frc.subRender) {
            this.context.dispatch({ type: FootprintFlowActionType.Set, payload: fc })
        }
        return fc
    }
}
