import { AmountInput } from '../Input/Amount'
import { UnitType } from '../../services/unit'
import ActivityService, { ActivityItem } from '../../services/activity'
import TaxonomyService from '../../services/taxonomy'
import FlagService, { FlagType } from '../../services/flag'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { StandardAttributes } from '../../types'
import { RenewableProduction } from '../../services/electricity'
import { VariableServicesContext } from '../../services'
import { useSave } from '../../hooks/useSave'
import { DebugData } from '../Footnotes'
import { Invoice, SolarPanel } from '@phosphor-icons/react'
import { PrettyNumber } from '../PrettyNumber'
import Utils from '../../services/utils'
import { UnitSelector } from '../Input/UnitSelector'
import { FileManager } from '../Input/FileUploader'

export const RenewableProductionEditor = (
    props: StandardAttributes & {
        activityItem?: ActivityItem
        onChange: (renewableProduction?: RenewableProduction) => void
    },
) => {
    const { electricityService } = useContext(VariableServicesContext)
    const [renewableProduction, setRenewableProduction] = useState<RenewableProduction>()
    const [loading, setLoading] = useState<boolean>(false)

    useEffect(() => setRenewableProduction(undefined), [props.activityItem?.uuid])

    const taxonomy = useMemo(() => {
        if (!props.activityItem) return undefined
        return ActivityService.getActivityTaxonomy(props.activityItem)
    }, [props.activityItem?.product?.taxonomy, props.activityItem?.taxonomy])

    const partialSaveFn = useCallback(
        async (properties?: Partial<RenewableProduction>) => {
            setRenewableProduction((prev) => ({ ...prev, ...properties }))
            if (properties && props.activityItem?.uuid) properties.node = { uuid: props.activityItem.uuid }
            return electricityService.saveRenewableProduction(properties).then((ec) => {
                setRenewableProduction(ec)
                props.onChange(ec)
                return ec
            })
        },
        [props.activityItem?.uuid],
    )

    const { pSave, saving } = useSave({ node: renewableProduction, partialSaveFn })

    const className = useMemo(() => {
        if (renewableProduction?.quantity) return 'bg-info bg-opacity-10'
        return 'bg-light'
    }, [renewableProduction?.quantity])

    useEffect(() => {
        if (props.activityItem?.renewableProduction?.uuid) {
            setLoading(true)
            electricityService
                .getRenewableProduction(props.activityItem.renewableProduction.uuid)
                .then(setRenewableProduction)
                .finally(() => setLoading(false))
        }
    }, [props.activityItem?.renewableProduction?.uuid])

    if (
        !props.activityItem ||
        !taxonomy?.path?.startsWith(TaxonomyService.electricityPath) ||
        !FlagService.enabledFlags.has(FlagType.EnableElectricity)
    )
        return null

    return (
        <>
            <div className={`${className} my-3 p-2 rounded-2`}>
                <div className='d-flex align-items-center gap-1'>
                    <label htmlFor={`renewableProduction-${props.activityItem?.uuid}`} className='small'>
                        <SolarPanel /> Produced renewable electricity
                        {(saving || loading) && <span className='ms-1 spinner-border spinner-border-sm' />}
                    </label>
                    <AmountInput
                        extraClassName='ms-auto small'
                        disabled={props.disabled || !props.activityItem.quantity}
                        amount={{ quantity: renewableProduction?.quantity, unit: props.activityItem.unit }}
                        inputFieldProps={{
                            id: `renewableProduction-${props.activityItem?.uuid}`,
                            style: { width: '6rem' },
                            max: props.activityItem?.quantity,
                            min: 0,
                            placeholder: '0',
                        }}
                        unitSelectorProps={{ hardCodeUnit: props.activityItem?.unit, unitType: UnitType.ENERGY }}
                        onChange={(amount) => pSave({ quantity: amount?.quantity })}
                    />
                </div>
                <FileManager
                    className='mt-2'
                    nodeId={renewableProduction?.uuid}
                    nodeType='RenewableProduction'
                    disabled={props.disabled}
                />
                <DebugData node={renewableProduction} />
            </div>
            <div className='p-2 bg-light rounded-2'>
                <div className='small d-flex align-items-center gap-1'>
                    <Invoice /> Purchased electricity{' '}
                    <PrettyNumber
                        extraClassName='ms-auto me-2'
                        num={Utils.Decimal(props.activityItem.quantity || 0)
                            .minus(renewableProduction?.quantity || 0)
                            .toNumber()}
                    />
                    <UnitSelector onChange={() => {}} hardCodeUnit={props.activityItem.unit} />
                </div>
                <FileManager
                    className='mt-2'
                    nodeId={props.activityItem?.uuid}
                    nodeType='ActivityItem'
                    disabled={props.disabled}
                />
            </div>
        </>
    )
}
