import Tooltip from '../Tooltip'
import { AmountInput } from '../Input/Amount'
import { UnitType } from '../../services/unit'
import ActivityService, { ActivityItem } from '../../services/activity'
import { StandardAttributes } from '../../types'
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { Leaf } from '@phosphor-icons/react'
import TaxonomyService from '../../services/taxonomy'
import FlagService, { FlagType } from '../../services/flag'
import { FileManager } from '../Input/FileUploader'
import { ElectricityCertificate } from '../../services/electricity'
import { VariableServicesContext } from '../../services'
import { useSave } from '../../hooks/useSave'
import { DebugData } from '../Footnotes'

export const GoOptions = (
    props: StandardAttributes & {
        node?: ActivityItem
        onChange: (electricityCertificate?: ElectricityCertificate) => void
    },
) => {
    const { electricityService } = useContext(VariableServicesContext)
    const [electricityCertificate, setElectricityCertificate] = useState<ElectricityCertificate>()
    const [loading, setLoading] = useState<boolean>(false)
    const inputRef = useRef<HTMLInputElement>(null)

    useEffect(() => setElectricityCertificate(undefined), [props.node?.uuid])

    useEffect(() => {
        if (props.node?.electricityCertificate?.uuid) {
            setLoading(true)
            electricityService
                .getCertificate(props.node.electricityCertificate.uuid)
                .then(setElectricityCertificate)
                .finally(() => setLoading(false))
        }
    }, [props.node?.electricityCertificate?.uuid])

    const partialSaveFn = useCallback(
        async (properties?: Partial<ElectricityCertificate>) => {
            setElectricityCertificate((prev) => ({ ...prev, ...properties }))
            if (properties && props.node?.uuid) properties.node = { uuid: props.node.uuid }
            return electricityService.saveCertificate(properties).then((ec) => {
                setElectricityCertificate(ec)
                return ec
            })
        },
        [props.node?.uuid],
    )

    const { pSave, saving } = useSave({ node: electricityCertificate, partialSaveFn, onSuccess: props.onChange })

    const taxonomy = useMemo(() => {
        if (!props.node) return undefined
        return ActivityService.getActivityTaxonomy(props.node)
    }, [props.node?.product?.taxonomy, props.node?.taxonomy])

    const purchasedElectricity = useMemo(() => {
        if (!props.node?.quantity) return 1
        return (props.node?.quantity || 0) - (props.node?.renewableProduction?.quantity || 0)
    }, [props.node?.quantity, props.node?.renewableProduction])

    if (
        !props.node?.uuid ||
        !taxonomy?.path?.startsWith(TaxonomyService.electricityPath) ||
        !FlagService.enabledFlags.has(FlagType.EnableElectricity)
    )
        return null

    return (
        <div
            className={[
                'd-flex flex-column align-items-start gap-2 p-2 rounded-2',
                electricityCertificate?.quantity ? 'bg-success bg-opacity-10' : 'bg-light',
            ].join(' ')}
        >
            <Tooltip
                trigger='hover'
                tooltipClassName='small'
                tooltipContent='Guarantees of Origin Certificate or Renewable Energy Certificate'
                className='small d-flex align-items-center gap-1 w-100'
            >
                <Leaf /> Green energy certificates (GOs, RECs)
                {(saving || loading) && <span className='ms-1 spinner-border spinner-border-sm' />}
                <AmountInput
                    extraClassName='ms-auto'
                    amount={{ quantity: electricityCertificate?.quantity, unit: props.node?.unit }}
                    inputFieldProps={{
                        passedRef: inputRef,
                        style: { width: '6rem' },
                        max: props.node.quantity,
                        min: 0,
                        disabled: props.disabled || !purchasedElectricity,
                        placeholder: '0',
                    }}
                    unitSelectorProps={{ hardCodeUnit: props.node?.unit, unitType: UnitType.ENERGY }}
                    onChange={(amount) => pSave({ quantity: amount?.quantity })}
                />
            </Tooltip>
            <FileManager
                nodeId={electricityCertificate?.uuid}
                nodeType='ElectricityCertificate'
                disabled={props.disabled}
            />
            <DebugData node={electricityCertificate} />
        </div>
    )
}
