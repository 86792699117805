import { KeyValuePair, VariableBaseNode } from '../types'
import { ActionMap } from '../context'
import { UnitString } from '../components/CO2e'
import Utils from './utils'
import VariableService from './service'
import { Product } from './product'
import { Part } from './part'
import { UIOptionActionType } from './ui'

export enum UnitType {
    WEIGHT = 'weight',
    WEIGHT_OVER_TIME = 'weight_over_time',
    USE = 'use',
    AREA = 'area',
    AREA_OVER_TIME = 'area_over_time',
    VOLUME = 'volume',
    TIME = 'time',
    CURRENCY = 'currency',
    DISTANCE = 'distance',
    FREIGHT = 'freight',
    PASSENGER = 'passenger',
    ENERGY = 'energy',
    UNKNOWN = 'unknown',
}

export const unitTypes: KeyValuePair<UnitType>[] = [
    { value: UnitType.WEIGHT, name: 'Weight', text: 'Weight', description: 'kilograms, pounds...' },
    { value: UnitType.AREA, name: 'Area', text: 'Area', description: 'square meters/feet, acres...' },
    { value: UnitType.VOLUME, name: 'Volume', text: 'Volume', description: 'cubic meters, liters, gallons...' },
    { value: UnitType.TIME, name: 'Time', text: 'Time', description: 'days, weeks, years, lifetimes...' },
    { value: UnitType.CURRENCY, name: 'Currency', text: 'Currency', description: 'dollars, euros, kroner...' },
    { value: UnitType.DISTANCE, name: 'Distance', text: 'Distance', description: 'kilometers, miles...' },
    { value: UnitType.FREIGHT, name: 'Freight', text: 'Freight', description: 'tonne kilometers/miles...' },
    { value: UnitType.PASSENGER, name: 'Passenger', text: 'Passenger', description: 'passenger kilometers/miles' },
    { value: UnitType.ENERGY, name: 'Energy', text: 'Energy', description: 'kilowatt hours, joules...' },
    { value: UnitType.UNKNOWN, name: 'Per unit', text: 'Per unit', description: 'items, pieces...' },
    { value: UnitType.USE, name: 'Use', text: 'Use', description: 'room per night, trip...' },
]

export type UnitSize = 'small' | 'large'

export interface Unit extends VariableBaseNode {
    name: string
    code: string
    isBaseUnit: boolean
    toBaseUnit?: number
    fromBaseUnit?: number
    type: UnitType
}

type UnitIndex = {
    [key: string]: Unit
}

export const EmptyUnit: Unit = {
    name: '',
    code: '',
    type: UnitType.UNKNOWN,
    isBaseUnit: false,
}

export enum UnitActionType {
    SetLarge = 'SetLargeUnit',
    SetSmall = 'SetSmallUnit',
}

type UnitActionPayload = {
    [UnitActionType.SetLarge]: UnitString
    [UnitActionType.SetSmall]: UnitString
}

export type UnitActions = ActionMap<UnitActionPayload>[keyof ActionMap<UnitActionPayload>]

export const UnitReducer = (state: Unit[] | undefined, action: UnitActions): Unit[] | undefined => {
    switch (action.type) {
        default:
            return state
    }
}

export const UnitLargeReducer = (state: UnitString | undefined, action: UnitActions): UnitString | undefined => {
    if (action.type === UnitActionType.SetLarge) {
        Utils.bigCo2eNumbers = action.payload
        return action.payload
    }
    return state
}

export const UnitSmallReducer = (state: UnitString | undefined, action: UnitActions): UnitString | undefined => {
    if (action.type === UnitActionType.SetSmall) {
        Utils.smallCo2eNumbers = action.payload
        return action.payload
    }
    return state
}

export default class UnitService extends VariableService {
    private basePath: string = '/unit'
    private static DefaultUnit: Unit
    private static DefaultUnitCode: string = 'item'

    public static ready: boolean = false
    public static units: Unit[] = []
    public static unitByCode: UnitIndex = {}
    public static unitByUuid: UnitIndex = {}
    public static unitsByType: { [key: string]: Unit[] } = {}
    public static baseUnitByType: Map<string, Unit> = new Map()

    public static getDefaultUnit(): Unit {
        return this.DefaultUnit
    }

    public static unitsWithWeight = [UnitType.WEIGHT, UnitType.VOLUME, UnitType.AREA, UnitType.UNKNOWN]

    public static getNewUnit(currentUnit?: Unit, product?: Product | Part): Unit | undefined {
        const newUnit = product?.unit
        if (newUnit?.type === UnitType.USE || newUnit?.type === UnitType.UNKNOWN) {
            return newUnit
        }
        if (newUnit && newUnit.type !== currentUnit?.type) {
            return UnitService.baseUnitByType.get(newUnit.type)
        }
        if (!currentUnit) {
            return UnitService.getDefaultUnit()
        }
        return currentUnit
    }

    public valueSmallUnit(value?: string | number | null): number | undefined {
        return Utils.Decimal(value || 0)
            .times(UnitService.unitByCode[this.context.stores.unitSmall || 'kg']?.toBaseUnit || 1)
            .toNumber()
    }

    public async getUnits(): Promise<Unit[]> {
        return this.httpService.get<Unit[]>(this.basePath).then((units) => {
            units.forEach((unit) => {
                UnitService.unitByCode[unit.code] = unit
                if (unit.uuid) {
                    UnitService.unitByUuid[unit.uuid] = unit
                }
                if (unit.code === UnitService.DefaultUnitCode) {
                    UnitService.DefaultUnit = unit
                }
                if (!UnitService.unitsByType[unit.type]) {
                    UnitService.unitsByType[unit.type] = []
                }
                if (!UnitService.unitsByType[unit.type].find((u) => u.uuid === unit.uuid)) {
                    UnitService.unitsByType[unit.type].push(unit)
                }
                if (unit.isBaseUnit) {
                    UnitService.baseUnitByType.set(unit.type, unit)
                }
            })
            UnitService.units = units
            UnitService.ready = true
            this.context.dispatch({ type: UIOptionActionType.UnitsReady, payload: true })
            return units
        })
    }

    public create(unit: Unit): Promise<Unit> {
        return this.httpService.post<Unit>(this.basePath, {
            body: JSON.stringify({
                unit: unit,
            }),
        })
    }

    public update(unit: Unit): Promise<Unit> {
        return this.httpService.put<Unit>(`${this.basePath}/${unit.uuid}`, {
            body: JSON.stringify({
                unit: unit,
            }),
        })
    }
}
