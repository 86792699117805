import { Modal } from '../Modal'
import { useContext, useEffect, useMemo, useState } from 'react'
import { ApplicationContext } from '../../context'
import { Filter, FilterActionType } from '../../services/filter'
import Button from './Button'
import Delete from '../Delete'
import { VariableServicesContext } from '../../services'
import { defaultFilters, Filters } from './Filters'
import Utils from '../../services/utils'
import { Plus } from '@phosphor-icons/react'
import InputField from './InputField'

export const FilterManager = () => {
    const context = useContext(ApplicationContext)
    const { filterService } = useContext(VariableServicesContext)
    const [filters, setFilters] = useState<Filter[]>([])

    useEffect(() => {
        if (!context.stores.filters.list.length) filterService.getFilters()
    }, [])

    useEffect(() => {
        setFilters([...context.stores.filters.list])
    }, [context.stores.filters.list])

    const content = useMemo(() => {
        return (
            <div className='d-table small w-100'>
                <div className='d-table-header-group'>
                    <div className='d-table-row'>
                        <div className='d-table-cell align-middle'>Name</div>
                        <div className='d-table-cell align-middle'>Settings</div>
                        <div className='d-table-cell align-middle' />
                    </div>
                </div>
                <div className='d-table-row-group'>
                    {filters.map((f) => (
                        <FilterEditor filter={f} key={`filter-${f.uuid}`} />
                    ))}
                </div>
            </div>
        )
    }, [filters])

    const footer = useMemo(() => {
        return (
            <div className='d-flex align-items-center justify-content-between gap-2 w-100'>
                <Button
                    className='btn btn-sm btn-light'
                    onClick={() => setFilters((state) => [...state, { name: '' }])}
                >
                    <Plus /> Filter
                </Button>
                <Button
                    className='btn btn-sm btn-dark'
                    onClick={() => context.dispatch({ type: FilterActionType.ShowManager, payload: false })}
                >
                    Done
                </Button>
            </div>
        )
    }, [])

    return (
        <Modal
            hidden={!context.stores.filters.showManager}
            header='Saved filters'
            size='lg'
            content={content}
            footer={footer}
            onVisibilityChange={(isVisible) => {
                if (!isVisible) {
                    context.dispatch({ type: FilterActionType.ShowManager, payload: false })
                }
            }}
        />
    )
}

export const FilterEditor = (props: { filter: Filter }) => {
    const { filterService } = useContext(VariableServicesContext)
    const [filter, setFilter] = useState<Filter>(props.filter)
    const [saving, setSaving] = useState<boolean>(false)
    const [shouldSave, setShouldSave] = useState<boolean>(false)

    useEffect(() => {
        if (shouldSave) {
            setShouldSave(false)
            setSaving(true)
            filterService
                .saveFilter(filter)
                .catch(Utils.errorToast)
                .finally(() => setSaving(false))
        }
    }, [shouldSave])

    return (
        <div className='d-table-row' key={`filter-${filter.uuid}`}>
            <div className='d-table-cell align-middle'>
                <InputField
                    placeholder='Filter name'
                    defaultValue={filter.name}
                    focusOnRender={!filter.name}
                    className='variable-form-control bg-light w-100'
                    onChange={(newValue) => setFilter((state) => ({ ...state, name: newValue }))}
                    onEnterOrBlur={setShouldSave}
                />
            </div>
            <div className='d-table-cell align-middle'>
                <Filters
                    showFilterSelector={false}
                    showClearButton={false}
                    filters={defaultFilters}
                    savedFilter={filter}
                    className='d-flex align-items-start flex-column gap-1 fs-base'
                    queryOptions={filter.queryOptions}
                    dataSourceSelectorProps={{ showAllSources: true }}
                    onChange={(newQueryOptions) => {
                        const qo = Utils.mergeObjects(filter.queryOptions, newQueryOptions)
                        setFilter({ ...filter, queryOptions: qo })
                        setShouldSave(true)
                    }}
                />
            </div>
            <div className='d-table-cell align-middle text-end'>
                <div className='d-flex gap-1 justify-content-end'>
                    <div style={{ width: '1rem' }}>
                        <span hidden={!saving} className='spinner-border spinner-border-sm' />
                    </div>
                    <Delete
                        iconOnly={true}
                        deleteFn={async () => {
                            if (!filter.uuid) {
                                return filterService.getFilters().then()
                            } else {
                                return filterService
                                    .deleteFilter(filter)
                                    .catch(Utils.errorToast)
                                    .then(() => Utils.successToast('Filter deleted'))
                                    .then(() => undefined)
                            }
                        }}
                    />
                </div>
            </div>
        </div>
    )
}
