import { ActionMap, ApplicationContextInterface } from '../context'
import { ActivityListViewOption, ReportDetailType } from './activity'
import ProductService, { Product, ProductType } from './product'
import { QueryOptions, QueryWhen, TimePeriod, VariableBaseNode, VariableNode, VariableNodeType } from '../types'
import PartService, { Part } from './part'
import { ReactNode } from 'react'
import { ColorMode } from './user'
import { Inventory, InventoryService, InventoryType } from './inventory'
import VariableService from './service'
import { AnalyticsService } from './analytics'
import Utils from './utils'
import { TransportType } from './transport'
import semver from 'semver'
import { DataImportResponse, DataImportType } from './dataImport'

interface UiSettings extends VariableBaseNode {
    reportDetailType?: ReportDetailType
}

export type Breadcrumb = {
    type: ProductType
    node: Product | Part
}

export interface NavItem {
    id?: string
    url?: string
    target?: string
    matchPaths?: string[]
    icon?: ReactNode
    text: string
    keywords?: string
    alertKeys?: string[]
    alertCount?: number
    active?: boolean
    className?: string
    typeString: 'header' | 'nav' | 'create' | string
    exactMatch?: boolean
    hidden?: boolean
    invisible?: boolean
    keyboardShortcut?: string
    parentPath?: string
    subNav?: NavItem[]
}

export interface IDeleteModal {
    confirmText?: string
    warningText?: string
    deleteFn: () => Promise<void>
}

export interface UIOptions {
    uiVersion?: string
    apiVersion?: string
    colorMode?: string
    pageTitle?: string
    pageCategory?: string
    showMobileHeader?: boolean
    navPath?: string
    showChat?: boolean
    showLoggedOutSidebar?: boolean
    showElectricityCreator?: boolean
    activityListCo2e?: ActivityListViewOption
    footprintSearchConfig?: FootprintSearchConfig
    deleteModal?: IDeleteModal
    layers?: string[]
    hotKey?: string
    metaKey?: boolean
    altKey?: boolean
    shiftKey?: boolean
    ctrlKey?: boolean
    modifierKey?: boolean
    escapeKey?: number
    panelClick?: number
    showRequestFootprintForm?: { name?: string }
    showUpgradeModal?: boolean
    flagsReady?: boolean
    unitsReady?: boolean
    ghgReady?: boolean
    taxonomyReady?: boolean
    useStagesReady?: boolean
    locationId?: string
    geoLocationsUpdated?: number
    locationsUpdated?: number
    dataSourcesUpdated?: number
    apiUsersUpdated?: number
    breadcrumbsUpdated?: number
    plansUpdated?: number
    planId?: string
    timelinesUpdated?: number
    timelineId?: string
    targetId?: string
    showStartImport?: DataImportType
    dataImportResult?: DataImportResponse
}

export interface IChartConfig {
    when?: QueryWhen
    period?: TimePeriod
    dateFormat: string
    friendlyFormat: string
    shortFormat: string
    unit: string
}

export interface IChartData extends IChartConfig {
    data: any[]
    columns: string[]
    max: number
}

export type FootprintSearchView = 'global' | 'inventory' | 'database'

export interface FootprintSearchConfig {
    instanceId?: string
    title?: string
    targetNode?: VariableNode
    targetType?: VariableNodeType
    footprint?: Product
    part?: Part
    transportType?: TransportType
    visible?: boolean
    queryOptions?: QueryOptions
    inventoryTypes?: InventoryType[]
    view?: FootprintSearchView
}

export enum UIOptionActionType {
    SetUiVersion = 'SetUiVersion',
    SetColorMode = 'SetColorMode',
    SetActivityListCo2e = 'SetActivityListCo2e',
    SetNavPath = 'SetNavPath',
    SetShowMobileHeader = 'SetShowMobileHeader',
    SetShowChat = 'SetShowChat',
    ResetNavPath = 'ResetNavPath',
    ShowLoggedOutSidebar = 'ShowLoggedOutSidebar',
    SetFootprintSearchConfig = 'SetFootprintSearchConfig',
    ShowFootprintSearch = 'ShowFootprintSearch',
    ShowDeleteModal = 'ShowDeleteModal',
    ShowElectricityCreator = 'ShowElectricityCreator',
    HideFootprintSearch = 'HideFootprintSearch',
    ClearFootprintSearch = 'ClearFootprintSearch',
    ToggleFootprintSearch = 'ToggleFootprintSearch',
    SelectNode = 'CmdPaletteSelectNode',
    AddUiLayer = 'AddUiLayer',
    RemoveUiLayer = 'RemoveUiLayer',
    SetPanelClick = 'SetPanelClick',
    SetModifierKeys = 'SetModifierKeys',
    EscapeKey = 'EscapeKey',
    ShowRequestFootprintForm = 'ShowRequestFootprintForm',
    ShowUpgradeModal = 'ShowUpgradeModal',
    FlagsReady = 'FlagsReady',
    UnitsReady = 'UnitsReady',
    GhgReady = 'GhgReady',
    TaxonomyReady = 'TaxonomyReady',
    UseStagesReady = 'UseStagesReady',
    SetLocationId = 'SetLocationId',
    SetGeoLocationsUpdated = 'SetGeoLocationsUpdated',
    LocationsUpdated = 'LocationsUpdated',
    DataSourcesUpdated = 'DataSourcesUpdated',
    ApiUsersUpdated = 'ApiUsersUpdated',
    BreadcrumbsUpdated = 'BreadcrumbsUpdated',
    PlansUpdated = 'PlansUpdated',
    SetPlanId = 'SetPlanId',
    SetTimelineId = 'SetTimelineId',
    SetTargetId = 'SetTargetId',
    TimelinesUpdated = 'TimelinesUpdated',
    ShowStartImport = 'ShowStartImport',
    SetDataImportResult = 'SetDataImportResult',
}

type UIOptionActionPayload = {
    [UIOptionActionType.SetUiVersion]: string
    [UIOptionActionType.SetColorMode]: ColorMode
    [UIOptionActionType.SetActivityListCo2e]: ActivityListViewOption
    [UIOptionActionType.SetNavPath]: string
    [UIOptionActionType.SetShowMobileHeader]: boolean
    [UIOptionActionType.SetShowChat]: boolean
    [UIOptionActionType.ResetNavPath]: undefined
    [UIOptionActionType.ShowLoggedOutSidebar]: boolean
    [UIOptionActionType.SetFootprintSearchConfig]: FootprintSearchConfig
    [UIOptionActionType.ShowFootprintSearch]: undefined
    [UIOptionActionType.ShowDeleteModal]: IDeleteModal | undefined
    [UIOptionActionType.ShowElectricityCreator]: boolean
    [UIOptionActionType.HideFootprintSearch]: undefined
    [UIOptionActionType.ClearFootprintSearch]: undefined
    [UIOptionActionType.ToggleFootprintSearch]: undefined
    [UIOptionActionType.SelectNode]: { product?: Product; part?: Part; transportType?: TransportType }
    [UIOptionActionType.AddUiLayer]: string
    [UIOptionActionType.RemoveUiLayer]: string
    [UIOptionActionType.SetPanelClick]: undefined
    [UIOptionActionType.SetModifierKeys]: { metaKey?: boolean; altKey?: boolean; shiftKey?: boolean; ctrlKey?: boolean }
    [UIOptionActionType.EscapeKey]: undefined
    [UIOptionActionType.ShowRequestFootprintForm]: { name?: string } | undefined
    [UIOptionActionType.ShowUpgradeModal]: boolean
    [UIOptionActionType.FlagsReady]: boolean
    [UIOptionActionType.UnitsReady]: boolean
    [UIOptionActionType.GhgReady]: boolean
    [UIOptionActionType.TaxonomyReady]: boolean
    [UIOptionActionType.UseStagesReady]: boolean
    [UIOptionActionType.SetLocationId]: string | undefined
    [UIOptionActionType.SetGeoLocationsUpdated]: undefined
    [UIOptionActionType.LocationsUpdated]: undefined
    [UIOptionActionType.DataSourcesUpdated]: undefined
    [UIOptionActionType.ApiUsersUpdated]: undefined
    [UIOptionActionType.BreadcrumbsUpdated]: undefined
    [UIOptionActionType.PlansUpdated]: undefined
    [UIOptionActionType.SetPlanId]: string | undefined
    [UIOptionActionType.SetTimelineId]: string | undefined
    [UIOptionActionType.SetTargetId]: string | undefined
    [UIOptionActionType.TimelinesUpdated]: undefined
    [UIOptionActionType.ShowStartImport]: DataImportType | undefined
    [UIOptionActionType.SetDataImportResult]: DataImportResponse
}

export type UIOptionActions = ActionMap<UIOptionActionPayload>[keyof ActionMap<UIOptionActionPayload>]

export const UIOptionReducer = (state: UIOptions | undefined, action: UIOptionActions): UIOptions | undefined => {
    let newOptions = { ...state }
    switch (action.type) {
        case UIOptionActionType.SetUiVersion:
            console.debug('UI Version', action.payload)
            newOptions = { ...state, uiVersion: action.payload }
            break
        case UIOptionActionType.SetColorMode:
            newOptions = { ...state, colorMode: action.payload }
            Utils.colorMode = action.payload
            break
        case UIOptionActionType.AddUiLayer:
            // console.log('+', action.payload)
            newOptions = { ...state, layers: [action.payload, ...(state?.layers || [])] }
            break
        case UIOptionActionType.RemoveUiLayer:
            // console.log('-', action.payload)
            newOptions = { ...state, layers: state?.layers?.filter((t) => t !== action.payload) }
            break
        case UIOptionActionType.EscapeKey:
            newOptions = {
                ...state,
                escapeKey: state?.escapeKey ? state?.escapeKey + 1 : 1,
                hotKey: undefined,
            }
            break
        case UIOptionActionType.ShowRequestFootprintForm:
            newOptions = { ...state, showRequestFootprintForm: action.payload }
            break
        case UIOptionActionType.ShowUpgradeModal:
            newOptions = { ...state, showUpgradeModal: action.payload }
            break
        case UIOptionActionType.SetPanelClick:
            newOptions = { ...state, panelClick: state?.panelClick ? state?.panelClick + 1 : 1 }
            break
        case UIOptionActionType.SetModifierKeys:
            newOptions = {
                ...state,
                ...action.payload,
                modifierKey:
                    action.payload.metaKey ||
                    action.payload.altKey ||
                    action.payload.shiftKey ||
                    action.payload.ctrlKey,
            }
            break
        case UIOptionActionType.SetActivityListCo2e:
            newOptions = { ...state, activityListCo2e: action.payload }
            UiService.uiOptions = { activityListCo2e: action.payload }
            break
        case UIOptionActionType.SetNavPath:
            newOptions = { ...state, navPath: action.payload }
            break
        case UIOptionActionType.ResetNavPath:
            newOptions = { ...state, navPath: undefined }
            break
        case UIOptionActionType.SetShowChat:
            newOptions = { ...state, showChat: action.payload }
            break
        case UIOptionActionType.SetShowMobileHeader:
            newOptions = { ...state, showMobileHeader: action.payload }
            break
        case UIOptionActionType.ShowLoggedOutSidebar:
            newOptions = { ...state, showLoggedOutSidebar: action.payload }
            break
        case UIOptionActionType.SetFootprintSearchConfig:
            newOptions = { ...state, footprintSearchConfig: action.payload }
            break
        case UIOptionActionType.ShowFootprintSearch:
            newOptions = {
                ...state,
                footprintSearchConfig: { ...state?.footprintSearchConfig, visible: true },
            }
            break
        case UIOptionActionType.ShowDeleteModal:
            newOptions = { ...state, deleteModal: action.payload }
            break
        case UIOptionActionType.HideFootprintSearch:
            newOptions = {
                ...state,
                footprintSearchConfig: { ...state?.footprintSearchConfig, visible: false },
            }
            break
        case UIOptionActionType.ClearFootprintSearch:
            newOptions = {
                ...state,
                footprintSearchConfig: { visible: false, view: 'database' },
            }
            break
        case UIOptionActionType.ToggleFootprintSearch:
            newOptions = {
                ...state,
                footprintSearchConfig: { ...state?.footprintSearchConfig, visible: !state?.footprintSearchConfig },
            }
            break
        case UIOptionActionType.SelectNode:
            newOptions = {
                ...state,
                footprintSearchConfig: {
                    ...state?.footprintSearchConfig,
                    footprint: action.payload.product,
                    part: action.payload.part,
                    transportType: action.payload.transportType,
                },
            }
            break
        case UIOptionActionType.ShowElectricityCreator:
            newOptions = { ...state, showElectricityCreator: action.payload }
            break
        case UIOptionActionType.FlagsReady:
            newOptions = { ...state, flagsReady: true }
            break
        case UIOptionActionType.UnitsReady:
            newOptions = { ...state, unitsReady: true }
            break
        case UIOptionActionType.GhgReady:
            newOptions = { ...state, ghgReady: true }
            break
        case UIOptionActionType.TaxonomyReady:
            newOptions = { ...state, taxonomyReady: true }
            break
        case UIOptionActionType.UseStagesReady:
            newOptions = { ...state, useStagesReady: true }
            break
        case UIOptionActionType.SetLocationId:
            newOptions = { ...state, locationId: action.payload }
            break
        case UIOptionActionType.SetGeoLocationsUpdated:
            newOptions = {
                ...state,
                geoLocationsUpdated: state?.geoLocationsUpdated ? state?.geoLocationsUpdated + 1 : 1,
            }
            break
        case UIOptionActionType.LocationsUpdated:
            newOptions = { ...state, locationsUpdated: state?.locationsUpdated ? state?.locationsUpdated + 1 : 1 }
            break
        case UIOptionActionType.DataSourcesUpdated:
            newOptions = { ...state, dataSourcesUpdated: state?.dataSourcesUpdated ? state?.dataSourcesUpdated + 1 : 1 }
            break
        case UIOptionActionType.ApiUsersUpdated:
            newOptions = { ...state, apiUsersUpdated: state?.apiUsersUpdated ? state?.apiUsersUpdated + 1 : 1 }
            break
        case UIOptionActionType.BreadcrumbsUpdated:
            newOptions = { ...state, breadcrumbsUpdated: state?.breadcrumbsUpdated ? state?.breadcrumbsUpdated + 1 : 1 }
            break
        case UIOptionActionType.PlansUpdated:
            newOptions = { ...state, plansUpdated: state?.plansUpdated ? state?.plansUpdated + 1 : 1 }
            break
        case UIOptionActionType.SetPlanId:
            newOptions = { ...state, planId: action.payload }
            break
        case UIOptionActionType.SetTimelineId:
            newOptions = { ...state, timelineId: action.payload }
            break
        case UIOptionActionType.SetTargetId:
            newOptions = { ...state, targetId: action.payload }
            break
        case UIOptionActionType.TimelinesUpdated:
            newOptions = { ...state, timelinesUpdated: state?.timelinesUpdated ? state?.timelinesUpdated + 1 : 1 }
            break
        case UIOptionActionType.ShowStartImport:
            newOptions = { ...state, showStartImport: action.payload }
            break
        case UIOptionActionType.SetDataImportResult:
            newOptions = { ...state, dataImportResult: action.payload }
            break
    }
    return newOptions
}

export default class UiService extends VariableService {
    private basePath: string = '/ui'
    public static settings: UiSettings = {}
    private static UI_OPTIONS = 'uiOptions'
    private analyticsService: AnalyticsService
    public static versionPath = '/version'

    public static hotKeys = new Map<string, () => void>()

    public static BreadcrumbHistory: Map<string, Breadcrumb> = new Map()
    public static Breadcrumbs: Breadcrumb[] = []

    public static onModalClose() {
        UiService._onModalClose?.()
        UiService._onModalClose = undefined
    }

    public static hasModalClose(): boolean {
        return !!UiService._onModalClose
    }

    private static _onModalClose: (() => void) | undefined = undefined

    public static setOnModalClose(callback?: () => void) {
        UiService._onModalClose = callback
    }

    constructor(context: ApplicationContextInterface) {
        super(context)
        this.analyticsService = new AnalyticsService(context)
    }

    setContext(context: ApplicationContextInterface) {
        super.setContext(context)
        this.analyticsService.setContext(context)
    }

    public getVersionDiff(currentVersion?: string, serverVersion?: string) {
        const _currentVersion = currentVersion || this.context.stores.ui?.uiVersion
        const _serverVersion = serverVersion || this.context.stores.status?.uiVersion
        if (!_currentVersion || !_serverVersion) return null
        if (_currentVersion === _serverVersion || !semver.valid(_currentVersion) || !semver.valid(_serverVersion)) {
            return null
        }
        if (!semver.gt(_serverVersion, _currentVersion)) {
            return null
        }
        return semver.diff(_currentVersion, _serverVersion)
    }

    public getVersionUpdateUrl(serverVersion?: string, from?: string) {
        return `${UiService.versionPath}?uiVersion=${serverVersion}&from=${from || '/'}`
    }

    public static get uiOptions(): UIOptions {
        const settings = localStorage.getItem(this.UI_OPTIONS) || '{}'
        return JSON.parse(settings) as UIOptions
    }

    public static set uiOptions(value) {
        if (!value) {
            localStorage.removeItem(this.UI_OPTIONS)
        } else {
            localStorage.setItem(this.UI_OPTIONS, JSON.stringify(value))
        }
    }

    public setTitle(title: string, category?: string, navPath?: string) {
        if (title) {
            document.title = `${title} - Variable`
            this.analyticsService.page(category)
        } else {
            document.title = 'Variable'
        }
        if (navPath) {
            this.context.dispatch({ type: UIOptionActionType.SetNavPath, payload: navPath })
        }
    }

    public setHotKey(hotKey?: string) {
        if (UiService.hotKeys.has(hotKey || '')) {
            UiService.hotKeys.get(hotKey || '')?.()
        }
    }

    public showLoggedOutSidebar(show: boolean) {
        this.context.dispatch({ type: UIOptionActionType.ShowLoggedOutSidebar, payload: show })
    }

    public setNavPath(navPath: string) {
        this.context.dispatch({ type: UIOptionActionType.SetNavPath, payload: navPath })
    }

    public setShowMobileHeader(show: boolean) {
        this.context.dispatch({ type: UIOptionActionType.SetShowMobileHeader, payload: show })
    }

    public setShowChat(show: boolean) {
        this.context.dispatch({ type: UIOptionActionType.SetShowChat, payload: show })
    }

    public showDeleteModal(deleteModal?: IDeleteModal) {
        this.context.dispatch({ type: UIOptionActionType.ShowDeleteModal, payload: deleteModal })
    }

    public showRequestFootprintForm(show: boolean = true, name: string = '') {
        if (!show) {
            this.context.dispatch({ type: UIOptionActionType.ShowRequestFootprintForm, payload: undefined })
        } else {
            this.context.dispatch({ type: UIOptionActionType.ShowRequestFootprintForm, payload: { name } })
        }
    }

    public setInventoryNavPath(inv?: Inventory) {
        if (inv?.originalProduct?.productOf?.uuid === this.context.stores.company?.uuid) {
            this.setNavPath(ProductService.webRootList)
        } else if (InventoryService.byId.get(inv?.uuid || '')) {
            this.setNavPath(InventoryService.webRoot)
        }
    }

    public resetNavPath() {
        this.context.dispatch({ type: UIOptionActionType.ResetNavPath })
    }

    public showUpgradeModal(show: boolean) {
        this.context.dispatch({ type: UIOptionActionType.ShowUpgradeModal, payload: show })
    }

    public addToBreadcrumb(breadcrumb: Breadcrumb) {
        if (!breadcrumb.node.uuid) return
        UiService.BreadcrumbHistory.delete(breadcrumb.node.uuid)
        UiService.BreadcrumbHistory.set(breadcrumb.node.uuid, breadcrumb)
        if (UiService.Breadcrumbs?.[UiService.Breadcrumbs.length - 1]?.node?.uuid !== breadcrumb.node.uuid) {
            UiService.Breadcrumbs.push(breadcrumb)
        }
        this.context.dispatch({ type: UIOptionActionType.BreadcrumbsUpdated })
    }

    public backToBreadcrumb(breadcrumb: Breadcrumb) {
        const index = Array.from(UiService.Breadcrumbs.values()).findIndex((b) => b.node.uuid === breadcrumb.node.uuid)
        if (index >= 0) {
            for (let i = UiService.Breadcrumbs.length - 1; i > index; i--) {
                UiService.Breadcrumbs.splice(i)
            }
            this.context.dispatch({ type: UIOptionActionType.BreadcrumbsUpdated })
        }
    }

    public backButton() {
        const last = Array.from(UiService.Breadcrumbs.values()).pop()
        if (last) {
            UiService.Breadcrumbs.pop()
            this.context.dispatch({ type: UIOptionActionType.BreadcrumbsUpdated })
        }
    }

    public resetBreadcrumb() {
        UiService.Breadcrumbs = []
        this.context.dispatch({ type: UIOptionActionType.BreadcrumbsUpdated })
    }

    public getBreadcrumbLink(breadcrumb: Breadcrumb) {
        return breadcrumb.type === 'part'
            ? PartService.getPartUrl(breadcrumb.node as Part)
            : ProductService.getProductUrl(breadcrumb.node as Product)
    }

    public async getUiSettings(): Promise<UiSettings> {
        return this.httpService.get<UiSettings>(this.basePath).then((s) => {
            UiService.settings = s
            return s
        })
    }

    public saveUiSettings(settings: Partial<UiSettings>) {
        this.httpService.put<UiSettings>(this.basePath, { body: JSON.stringify({ settings }) }).then((s) => {
            UiService.settings = { ...UiService.settings, ...s }
        })
    }
}
