import ProductService, { Product } from '../../services/product'
import { Part, PartType } from '../../services/part'
import { ReactNode, useContext, useEffect, useState } from 'react'
import { KeyValuePair, QueryOptions, VariableNode, VariableNodeType } from '../../types'
import { Selector } from '../Input/Selector'
import { useFootprintSelector } from '../../hooks/useFootprintSelector'
import { useFootprintModal } from '../../hooks/useFootprintModal'
import * as PopperJS from '@popperjs/core'
import { InventoryService, InventoryType } from '../../services/inventory'
import { VariableServicesContext } from '../../services'

const _addOptions: KeyValuePair<string>[] = [
    {
        name: `Select ${ProductService.elementTitle()}`,
        description: `from your ${ProductService.elementTitle(true)} or the Global Database`,
        value: 'select',
    },
    {
        name: `Create new ${ProductService.elementTitle()}`,
        description: `Create a new ${ProductService.elementTitle()} and add it to the list of options`,
        value: 'create',
    },
]

export const AddSourceProduct = (props: {
    targetType: VariableNodeType
    node: VariableNode
    placeholder?: string
    label?: ReactNode
    placement?: PopperJS.Placement
    className?: string
    btnClassName?: string
    btnHoverClassName?: string
    btnActiveClassName?: string
    inventoryTypes?: InventoryType[]
    queryOptions?: QueryOptions
    hidden?: boolean
    onDone: (sourceProduct: Product, part?: Part) => void
}) => {
    const { partService } = useContext(VariableServicesContext)
    const [option, setOption] = useState<string>()
    const [addOptions, setAddOptions] = useState<KeyValuePair<string>[]>()
    const part = props.node as Part
    const instanceId = `add-source-product-${props.node.uuid}`

    const showFootprintModal = useFootprintSelector({
        searchConfig: {
            instanceId: instanceId,
            targetType: props.targetType,
            targetNode: props.node,
            inventoryTypes: props.inventoryTypes || [InventoryType.Product],
            queryOptions: props.queryOptions,
            visible: true,
            view: 'inventory',
        },
        onSelectProduct: (footprint) => {
            if (footprint) props.onDone(footprint)
        },
        onSelectPart: (part) => {
            if (part) props.onDone(part.sourceProducts?.[0]!, part)
        },
    })

    const productPreview = useFootprintModal({
        onClose: (spId) => {
            const sp = InventoryService.byId.get(spId || '')?.originalProduct
            if (sp?.uuid) props.onDone(sp)
        },
    })

    useEffect(() => {
        const _options = [..._addOptions]
        if (props.targetType === 'Part' && part?.type !== PartType.CONFIG) {
            _options.push({
                name: 'Collect primary data',
                description: 'Invite your supplier to provide primary data',
                value: 'request',
            })
        }
        setAddOptions(_options)
    }, [props.node?.uuid])

    useEffect(() => {
        if (option === 'select') {
            showFootprintModal()
            setOption(undefined)
        } else if (option === 'create') {
            productPreview(ProductService.getEmptyProduct())
            setOption(undefined)
        } else if (option === 'request') {
            partService.showConnectModal(part)
        }
    }, [option])

    let btnClassName = 'btn btn-xs bg-transparent rounded-1 ms--1'
    let hoverClassName = 'bg-primary-hover'
    if (props.targetType === 'Part' && !part?.sourceProductCount) {
        btnClassName = 'btn btn-xs btn-secondary'
        hoverClassName = ' '
    }

    return (
        <div hidden={props.hidden} className={`fs-base ${props.className}`}>
            <Selector
                ariaLabel='Add Source Product'
                className={props.btnClassName || btnClassName}
                hoverClassName={props.btnHoverClassName || hoverClassName}
                activeClassName={props.btnActiveClassName}
                placeholder={props.placeholder || '+ Add alternative'}
                label={props.label}
                hideTextFilter={true}
                readonly={true}
                placement={props.placement || 'bottom-start'}
                options={addOptions}
                option={option}
                onSelect={(newValue) => setOption(newValue.value)}
            />
        </div>
    )
}
