import { CSSProperties, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import AuthenticationService from '../services/authentication'
import Utils from '../services/utils'
import ProductService from '../services/product'
import { SubscriptionService } from '../services/subscription'
import { NavItem } from '../services/ui'
import DataRequestService from '../services/dataRequest'
import CompanyService from '../services/company'
import ActivityService from '../services/activity'
import PlanService from '../services/plan'
import OrgService from '../services/org'
import UserService from '../services/user'
import { LegacyAuthButtons } from './Auth0'
import {
    ArrowCircleUp,
    ArrowDownRight,
    ArrowUpRight,
    ChartLine,
    ChartLineDown,
    CurrencyCircleDollar,
    Database,
    DownloadSimple,
    Factory,
    FileText,
    Gear,
    GlobeSimple,
    HouseSimple,
    Lightning,
    PlusCircle,
    StackOverflowLogo,
    Users,
    UsersThree,
} from '@phosphor-icons/react'
import BatchService from '../services/batch'
import { ApplicationContext } from '../context'
import DataImportService from '../services/dataImport'
import { Notification } from './Icons/Notification'
import Logo from './Logo'
import { UserCompanyList } from './Company/UserCompanyList'
import { Img } from './Img'
import Tooltip from './Tooltip'
import FlagService, { FlagType } from '../services/flag'
import TransportService from '../services/transport'
import { TransportIcons } from './Icons/TransportIcons'
import { InventoryService } from '../services/inventory'
import { InventoryIcon } from './Icons/InventoryIcon'
import { ProductIcon } from './Icons/ProductIcon'
import EnergyService from '../services/energy'
import LocationService from '../services/location'
import { ChatDialog } from './ChatDialog'
import { StandardAttributes } from '../types'
import { VariableServicesContext } from '../services'

const authRoutes = [
    AuthenticationService.signUpPath,
    AuthenticationService.signInPath,
    AuthenticationService.resetPasswordPath,
]

export const _navItems: NavItem[] = [
    {
        url: '/',
        text: 'Home',
        icon: <HouseSimple className=' ' />,
        keyboardShortcut: 'h',
        typeString: 'nav',
    },
    {
        url: ProductService.webRootDatabase,
        text: ProductService.databaseTitle,
        icon: <Database className=' ' />,
        keywords: 'database db',
        typeString: 'nav',
    },
    {
        text: 'Model',
        typeString: 'header',
        subNav: [
            {
                url: ProductService.webRootList,
                text: ProductService.webTitle(true),
                icon: <ProductIcon isProduct={true} className=' ' />,
                keywords: 'footprint',
                keyboardShortcut: 'p',
                typeString: 'nav',
            },
            {
                url: InventoryService.webRoot,
                icon: <InventoryIcon allInventory={true} className=' ' />,
                text: InventoryService.webTitle(),
                keyboardShortcut: 'i',
                typeString: 'nav',
            },
            {
                url: InventoryService.webRootMaterial,
                text: 'Material',
                icon: (
                    <span className='border-start ps-3 ms-2'>
                        <InventoryIcon className=' ' />
                    </span>
                ),
                keywords: 'material',
                typeString: 'nav',
            },
            {
                url: EnergyService.webRoot,
                text: EnergyService.webTitle(),
                icon: (
                    <span className='border-start ps-3 ms-2'>
                        <Lightning className=' ' />
                    </span>
                ),
                keywords: 'energy',
                typeString: 'nav',
                hidden: true,
                invisible: true,
            },
            // {
            //     url: '/process',
            //     text: 'Process',
            //     icon: (
            //         <span className='border-start ps-3 ms-2'>
            //             <Nut className=' ' />
            //         </span>
            //     ),
            //     keywords: 'transport',
            //     typeString: 'nav',
            // },
            {
                url: TransportService.webRoot,
                text: TransportService.webTitle(),
                icon: <TransportIcons className='border-start ps-3 ms-2' />,
                keywords: 'transport',
                typeString: 'nav',
            },
        ],
    },
    {
        text: 'Connect',
        typeString: 'header',
        subNav: [
            {
                url: CompanyService.webRootSupplierList,
                text: CompanyService.webTitleSupplier,
                icon: <Factory className=' ' />,
                keyboardShortcut: 's',
                typeString: 'nav',
            },
            {
                url: DataRequestService.webRootCollect,
                icon: <ArrowDownRight className=' ' />,
                alertKeys: ['pendingSupplierRequestCount'],
                text: 'Request',
                typeString: 'nav',
            },
        ],
    },
    {
        text: 'Reduce',
        typeString: 'header',
        subNav: [
            {
                url: ActivityService.webRootAnalytics,
                icon: <ChartLine className=' ' />,
                text: 'Analyze',
                typeString: 'nav',
            },
            {
                url: PlanService.webRoot,
                icon: <ChartLineDown className=' ' />,
                text: 'Plan',
                keyboardShortcut: 'x',
                typeString: 'nav',
            },
            {
                url: ActivityService.webRootReport,
                icon: <FileText className=' ' />,
                text: 'Report',
                typeString: 'nav',
            },
        ],
    },
    {
        text: 'Grow',
        typeString: 'header',
        subNav: [
            {
                url: CompanyService.webRootCustomerList,
                icon: <CurrencyCircleDollar className=' ' />,
                text: CompanyService.webTitleCustomer,
                typeString: 'nav',
            },
            {
                url: DataRequestService.webRootShare,
                icon: <ArrowUpRight className=' ' />,
                alertKeys: ['pendingCustomerRequestCount'],
                text: 'Deliver',
                typeString: 'nav',
            },
        ],
    },
    {
        text: 'Company',
        typeString: 'header',
        subNav: [
            {
                url: OrgService.webRoot,
                text: OrgService.webTitle(),
                icon: <UsersThree className=' ' />,
                keyboardShortcut: 'o',
                typeString: 'nav',
            },
            {
                url: LocationService.webRootList,
                text: LocationService.webTitle(true),
                icon: <GlobeSimple className=' ' />,
                keyboardShortcut: 'l',
                typeString: 'nav',
            },
        ],
    },
    {
        text: 'Track',
        typeString: 'header',
        className: 'mt-3',
        subNav: [
            {
                url: ActivityService.webRootActivities,
                text: ActivityService.webTitle(true),
                icon: <PlusCircle className=' ' />,
                alertKeys: ['activityPendingCount'],
                keyboardShortcut: 'd',
                typeString: 'nav',
            },
            {
                url: ActivityService.webRootActivityReports,
                icon: <DownloadSimple className=' ' />,
                text: 'Imports',
                matchPaths: [DataImportService.webRootImport],
                typeString: 'nav',
            },
            {
                url: BatchService.webRootList,
                text: BatchService.webTitle(true),
                icon: <StackOverflowLogo className=' ' />,
                matchPaths: [BatchService.webRoot],
                keywords: 'batch',
                typeString: 'nav',
                hidden: true,
                invisible: true,
            },
        ],
    },
    {
        url: CompanyService.webRootAdmin,
        text: 'Company',
        typeString: 'nav',
        keyboardShortcut: 'c',
        invisible: true,
        subNav: [
            {
                url: CompanyService.webRootAdmin,
                text: 'Settings',
                typeString: 'nav',
                exactMatch: true,
            },
        ],
    },
    {
        url: UserService.webRootProfile,
        text: 'Profile',
        typeString: 'nav',
        keyboardShortcut: 'u',
        invisible: true,
    },
].map((ni) => ({
    ...ni,
    subNav: ni.subNav?.map((sni) => ({
        ...sni,
        parentPath: ni.url,
    })),
}))

export const Navigation = (
    props: StandardAttributes & {
        id?: string
        navClassName?: string
        showLogo?: boolean
    },
) => {
    const context = useContext(ApplicationContext)
    const [navItems, setNavItems] = useState<NavItem[]>(_navItems)
    const [activeNavItem, setActiveNavItem] = useState<NavItem>()
    const [isAuthPage, setIsAuthPage] = useState(false)
    const [companyMenuOpen, setCompanyMenuOpen] = useState<boolean>(false)
    const location = useLocation()
    const path = location.pathname
    const ref = useRef<any>()

    useEffect(() => {
        const ni = setActive(_navItems)
        setNavItems(ni)
        const active = ni.find((ni) => ni.active)
        setActiveNavItem(active)
        const rootPath = '/' + document.location.pathname?.split('/')?.[1]
        setIsAuthPage(authRoutes.includes(rootPath))
    }, [location.pathname, context.stores.ui?.navPath, context.stores.ui?.flagsReady])

    const setActive = useCallback(
        (items: NavItem[]) => {
            const _path = context.stores.ui?.navPath || path
            return items.map((ni) => {
                if (ni.url === _path) {
                    ni.active = true
                } else if (ni.url) {
                    ni.active = ni.url !== '/' && ni.exactMatch !== true && _path.startsWith(ni.url?.replace(/s$/, ''))
                }
                if (!ni.active && ni.matchPaths) {
                    ni.matchPaths.forEach((mp) => {
                        if (_path.startsWith(mp)) {
                            ni.active = true
                        }
                    })
                }
                let subNavActive = false
                if (ni.subNav) {
                    ni.subNav = setActive(ni.subNav)
                    subNavActive = ni.subNav.some((sni) => sni.active)
                }
                if (subNavActive) {
                    ni.active = true
                }
                if (ni.url === BatchService.webRootList && FlagService.enabledFlags.has(FlagType.UseBatches)) {
                    ni.invisible = false
                    ni.hidden = false
                }
                if (ni.url === EnergyService.webRoot && FlagService.enabledFlags.has(FlagType.EnableElectricity)) {
                    ni.invisible = false
                    ni.hidden = false
                }
                return ni
            })
        },
        [context.stores.ui?.navPath, path, context.stores.ui?.flagsReady],
    )

    const mainLogo = useMemo(() => {
        if (AuthenticationService.accessToken && !FlagService.ready) {
            return (
                <div style={{ height: '25px' }} className='d-flex align-items-center m-3'>
                    <span className='spinner-border spinner-border-sm' />
                </div>
            )
        }

        if (FlagService.enabledFlags.has(FlagType.UseCustomerLogo) && context.stores.company?.uuid) {
            return (
                <Tooltip
                    interactive={true}
                    trigger='click'
                    placement='bottom-start'
                    visible={companyMenuOpen}
                    onVisibleChange={setCompanyMenuOpen}
                    hidden={!context.stores.company?.name}
                    offset={[14, -10]}
                    tooltipStyle={{ minWidth: '8rem' }}
                    tooltipContent={
                        <>
                            <Link
                                to={CompanyService.webRootAdmin}
                                className='dropdown-item small'
                                onClick={() => setCompanyMenuOpen(false)}
                            >
                                <Gear /> Settings
                            </Link>
                            <Link
                                to={CompanyService.webRootUsers}
                                className='dropdown-item small'
                                onClick={() => setCompanyMenuOpen(false)}
                            >
                                <Users /> Users
                            </Link>
                            <UserCompanyList extraClassName='border-top' />
                        </>
                    }
                    className='d-flex align-items-center gap-2 p-3 text-light text-opacity-75'
                >
                    <Img src={context.stores.company?.logoUrl} alt={context.stores.company?.name} size='25px' />
                    <div className='text-overflow-ellipsis nt-2' style={{ maxWidth: '10rem' }}>
                        {context.stores.company?.name || 'Company'}
                    </div>
                </Tooltip>
            )
        }

        return (
            <Link hidden={props.showLogo === false} to='/'>
                <Logo
                    className='m-3'
                    logoStyle='header'
                    version='logo-horizontal-ondark'
                    style={{ width: '120px', height: '25px' }}
                />
            </Link>
        )
    }, [
        companyMenuOpen,
        context.stores.ui?.flagsReady,
        context.stores.company?.uuid,
        context.stores.company?.name,
        context.stores.company?.logoUrl,
        props.showLogo,
    ])

    if (isAuthPage) return null

    return (
        <div
            id={props.id}
            aria-label='Main Navigation'
            className={[
                props.className || 'd-flex',
                'flex-column justify-content-between bg-primary',
                props.extraClassName,
            ].join(' ')}
            style={props.style}
        >
            {mainLogo}
            <nav ref={ref} className='flex-grow-1 overflow-y-scroll'>
                {!AuthenticationService.isAuthenticated && (
                    <div className='p-3'>
                        <LegacyAuthButtons />
                    </div>
                )}
                {AuthenticationService.isAuthenticated && (
                    <SignedInNav navItems={navItems} activeNavItem={activeNavItem} className={props.navClassName} />
                )}
            </nav>
            <ChatDialog />
        </div>
    )
}

export const SignedInNav = (props: {
    navItems: NavItem[]
    activeNavItem?: NavItem
    className?: string
    style?: CSSProperties
}) => (
    <div className={`d-flex flex-column ${props.className}`} style={props.style}>
        {props.navItems
            .filter((ni) => ni.invisible !== true)
            .map((ni, idx) => {
                const subNav = ni.subNav?.filter((sni) => sni.invisible !== true)
                const subNavActive = subNav?.some((sni) => sni.active)
                const isActive = !subNavActive && ni.active
                const navItemKey = `navItem-${idx}`
                const navItemId = ni.id || `navigation-${Utils.apocSlugify(ni.text)}`

                const _subNav = subNav
                    ?.filter((sn) => !sn.invisible)
                    ?.map((sn) => (
                        <Link
                            key={`navItem-${Utils.apocSlugify(sn.text)}`}
                            aria-label={`Navigate to ${sn.text}`}
                            className={['variable-nav-item', sn.active ? 'active' : '', sn.className].join(' ')}
                            to={sn.url || ''}
                        >
                            {sn.icon && <span className='me-2'>{sn.icon}</span>}
                            <span className='variable-nav-item-text'>
                                {sn.text}
                                <NavAlert ni={sn} color={Utils.fadedColor} />
                            </span>
                        </Link>
                    ))

                if (ni.typeString === 'header') {
                    return (
                        <div key={navItemKey} id={navItemId}>
                            <div className='variable-nav-header'>
                                {ni.icon && <span className='me-1'>{ni.icon}</span>}
                                {ni.text}
                            </div>
                            {_subNav}
                        </div>
                    )
                }

                return (
                    <div
                        key={navItemKey}
                        id={navItemId}
                        className={[
                            'variable-nav-item-cx',
                            ni.className,
                            ni.active || subNavActive ? 'active' : '',
                        ].join(' ')}
                    >
                        <Link
                            aria-label={`Navigate to ${ni.text}`}
                            className={[
                                'variable-nav-item parent',
                                isActive ? 'active' : '',
                                ni.active ? 'text-white' : '',
                            ].join(' ')}
                            to={ni.target || ni.url || ''}
                        >
                            {ni.icon && <span className='me-2'>{ni.icon}</span>}
                            <span className='variable-nav-item-text'>
                                {ni.text} <NavAlert ni={ni} color={Utils.bodyColor} />
                            </span>
                        </Link>
                        {_subNav}
                    </div>
                )
            })}
    </div>
)

export const NavAlert = (props: { ni?: NavItem; size?: number; color?: string }) => {
    const context = useContext(ApplicationContext)
    let alert = 0
    let alertTotal = 0
    props.ni?.alertKeys?.forEach((k, idx) => {
        const key = k as keyof typeof context.stores.status
        if (context.stores.status[key]) {
            const numValue = context.stores.status[key] as number
            if (idx === 0) {
                alert += numValue
            }
            alertTotal += numValue
        }
    })
    if (alertTotal < 1) {
        return null
    }
    return (
        <span className='nt-3'>
            <Notification count={alert} showNumber={alert > 0} color={props.color} />
        </span>
    )
}

export const PlanLabel = (props: { className?: string; buttonClassName?: string }) => {
    const context = useContext(ApplicationContext)
    const { uiService } = useContext(VariableServicesContext)
    const [planName, setPlanName] = useState<string | undefined>(
        context.stores.company?.subscription?.stripeName || context.stores.company?.subscription?.name,
    )

    useEffect(
        () =>
            setPlanName(context.stores.company?.subscription?.stripeName || context.stores.company?.subscription?.name),
        [context.stores.company?.subscription?.stripeName, context.stores.company?.subscription?.name],
    )

    if (planName !== 'Free') {
        return null
    }

    return (
        <span className={props.className}>
            <Link
                to={SubscriptionService.appPricingPath}
                onClick={(e) => {
                    e.preventDefault()
                    uiService.showUpgradeModal(true)
                }}
                className={props.buttonClassName || 'btn btn-sm shadow-none btn-secondary px-2 py-1 text-nowrap'}
            >
                <ArrowCircleUp size={Utils.verySmallIconSize} className='nt--1 me-1' />
                Upgrade
            </Link>
        </span>
    )
}
